import React, { useState, useEffect } from 'react';
import './Navbar.css';

export default function Navbar({ scrollToComponent }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 500);
  const [isOpen, setIsOpen] = useState(false);

  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 500);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <div className='Navbar'>
      <div className='nav-left'>
        <a className='nav-title'>Hullmen</a>
      </div>

      {/* Conditionally render based on isMobile */}
      {!isMobile ? (
        <div className='nav-right'>
          <button className='nav-btn' onClick={() => scrollToComponent('aboutUs')}>About Us</button>
          <button className='nav-btn' onClick={() => scrollToComponent('services')}>Services</button>
          <button className='nav-btn' onClick={() => scrollToComponent('contactUs')}>Contact Us</button>
        </div>
      ) : (
        <>
          <div className='burger-btn' onClick={toggleMenu}>
            &#9776;
          </div>

          {isOpen && (
            <div className='mobile-menu'>
              <button className='nav-btn' onClick={() => { toggleMenu(); scrollToComponent('aboutUs'); }}>About Us</button>
              <button className='nav-btn' onClick={() => { toggleMenu(); scrollToComponent('services'); }}>Services</button>
              <button className='nav-btn' onClick={() => { toggleMenu(); scrollToComponent('contactUs'); }}>Contact Us</button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
