
import './App.css';
import Navbar from './components/Navbar';
import Top from './Mainpage/Top';

function App() {
  return (
    <div>

      <Top/>
      
      
      
    </div>
  );
}

export default App;
