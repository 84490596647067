import React from 'react';
import "./Top.css"
import Navbar from '../components/Navbar';
import { useEffect, useRef,useState } from 'react';
import emailjs from 'emailjs-com';
import logo1 from '../images/second/logo_2.png';
import logo2 from '../images/second/vessel.webp';
import logo3 from '../images/second/logo3.jpg';
import logo4 from '../images/second/logo4.jpg';
import logo5 from '../images/second/logo5.jpg';
import logo6 from '../images/second/logo6.jpg';



export default function Top() {

  const aboutUsRef = useRef(null);
  const servicesRef = useRef(null);
  const contactUsRef = useRef(null);

  const scrollToComponent = (section) => {
    switch (section) {
      case 'aboutUs':
        aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'services':
        servicesRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'contactUs':
        contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        break;
    }
  };

  

  const [formData, setFormData] = useState({
    to_name: '',
    from_name: '',
    email: '', 
    message: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.send('service_0ho7hed', 'template_5n5pbcm', formData, '-LsaB0YpBGM1aZCJF')
    .then((result) => {
      console.log(result.text);
      alert("Email sent successfully!");

      // Reset form data after successful email send
      setFormData({
        to_name: '',
        from_name: '',
        email: '',
        message: '',
      });
  }, (error) => {
      console.log(error.text);
      alert("Failed to send email.");
  }); // Reset the form after submission
  };
  
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []); 

  const images = [
    require('../images/second/slide1.jpg'), // Replace with your actual image paths
    require('../images/second/wat2.jpg'),
    require('../images/second/slideshow2.avif'),
    require('../images/second/wat4.avif'),
    require('../images/second/wat5.jpg')
  
  ];

  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, [images.length]);


  return (
    <div className='body1'>
      <Navbar scrollToComponent={scrollToComponent} />
      <div className='main-head'>
        <div className='image-slideshow'>
       
        <img   src={images[currentImage]} alt="Slideshow" className="slideshow-img" />
        <div className='centered-text'>
          <div className='title-main1'>Hullmen</div>
          <div className='title-main2'>Shiplog PVT LTD</div>
        </div>

      </div>
      
      
      </div>
      <div ref={servicesRef} className='our-services'>
  <div className='our-services-text1'>
    Our Services
  </div>

  <div className='service-items'>
    <div className='service-item'>
      <img className='logo-image' src={logo2} alt='Vessel Operations Logo' />
      <p>Vessel Operations</p>
      <ul>
        <li>Vessel Entry and Exit Formalities</li>
        <li>Port State Control Assistance</li>
        <li>Bunker and Off-Hire Survey Arrangements</li>
      </ul>
    </div>

    <div className='service-item'>
      <img className='logo-image' src={logo1} alt='Crew Services Logo' />
      <p>Crew Services</p>
      <ul>
        <li>Crew Change (LOI, Visa Application)</li>
        <li>Special Visa Arrangements</li>
        <li>Crew Medical Assistance</li>
      </ul>
    </div>

    <div className='service-item'>
      <img className='logo-image' src={logo3} alt='Logistics and Provisions Logo' />
      <p>Logistics and Provisions</p>
      <ul>
        <li>Spare Parts Transit and Clearance</li>
        <li>Timely Delivery of Provisions</li>
        <li>Lubricants and Bunker Oil Sample Testing</li>
      </ul>
    </div>

    <div className='service-item'>
      <img className='logo-image' src={logo4} alt='Technical Support Logo' />
      <p>Technical Support</p>
      <ul>
        <li>Local Inspectors and Technicians</li>
        <li>Dry Dock Agency Services</li>
        <li>Onboard Services for Superintendents</li>
      </ul>
    </div>

    <div className='service-item'>
      <img className='logo-image' src={logo5} alt='Financial and Urgent Matters Logo' />
      <p>Financial and Urgent Matters</p>
      <ul>
        <li>Cash to Master (CTM)</li>
        <li>24/7 Urgent Matter Handling</li>
        <li>Cost-Effective Solutions for Ship Owners</li>
      </ul>
    </div>

    <div className='service-item'>
      <img className='logo-image' src={logo6} alt='Logistics and Freight Support Logo' />
      <p>Logistics and Freight Support</p>
      <ul>
        <li>Logistics Freight Support</li>
        <li>Custom Owner-Requested Services</li>
      </ul>
    </div>
  </div>
</div>


        
        

      
      <div ref={aboutUsRef} className='about-us'>
          <div className='about-us-text1'>
            ABOUT US

          </div>
          <div className='about-us-text2'>
  <ul>
    <li>Since serving the industries for the last one and a half decades, we have shipping experience in performing port agency, representing our principals’ interests in several office locations in Indian Ports.</li>
    <li>Every port call requires a nominated agent responsible for the operational and financial coordination between the vessel itself, the owner/operator of the vessel, and the port.</li>
    <li>Principals and nominators of any kind, including ship owners, charterers, operators, pools, brokers, traders, shippers, and receivers, in any sector (dry bulk, tankers, container ships, RO/RO, and cruise ships), rely on Steamer Agents to safeguard their interests during the port call and effectively assist Port State Control.</li>
    <li>Our quality and procedures are uniquely accredited to ensure the highest industry standards, documented with our customers in Service Level Agreements, ensuring adherence by measuring deliverables.</li>
    <li>An additional and distinguished benefit of our structure is our ability to deliver uniformity in areas such as reporting, messaging, documentation, and disbursements. Jyothi Group is the only global agent with an operating platform (POPDA) common across all our offices, integrated into our financial system, ensuring fast D/A turnaround time and high data quality.</li>
    <li>Our excellent relationships with local authorities in the countries we operate benefit our customers, as our high ethical standards, reliability, and quality of our services are recognized by all parties, facilitating local operation & documentation processing and eliminating the risk of vessel delays, crew members being held up, etc.</li>
    
  </ul>
</div>
</div>

<div ref={contactUsRef} className='contact-us'>
        <div className='contact-title'>
          Contact Us
        </div>
        <div className='contact-input'>
      <div className='input-container'>
        <form id="contact-form" onSubmit={sendEmail}>
          <div className='form-group'>
            <label htmlFor="to_name">To Name:</label>
            <input
              type="text"
              id="to_name"
              name="to_name"
              placeholder="Enter recipient's name"
              value={formData.to_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
  <label htmlFor="email">Email:</label>
  <input
    type="email"
    id="email"
    name="email"
    placeholder="Enter your email"
    value={formData.email}
    onChange={handleChange}
    required
  />
</div>

          <div className='form-group'>
            <label htmlFor="from_name"> Name:</label>
            <input
              type="text"
              id="from_name"
              name="from_name"
              placeholder="Enter your name"
              value={formData.from_name}
              onChange={handleChange}
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor="message">Message:</label>
            <textarea
              id="message"
              name="message"
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button className='button' type="submit">Submit</button>
        </form>
      </div>
    </div>

      </div>
        
    </div>
  )
}
